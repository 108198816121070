import { RoomStatus } from "@multimediallc/web-utils"
import { isLocalStorageSupported } from "@multimediallc/web-utils/modernizr"
import { PhotoVideos } from "../../cb/photovideos/photoVideos"
import { currentSiteSettings } from "../../cb/siteSettings"
import { isAnonymous } from "../auth"
import { BioContentTab } from "../bioContentTab"
import { roomLoaded } from "../context"
import { FanclubButton } from "../FanclubButton"
import { followingEvent, NOTIFY_UTM_CONTENT } from "../follow"
import { FollowUnfollowSection } from "../FollowUnfollowSection"
import { addPageAction } from "../newrelic"
import { RoomTabs } from "../roomTabs"
import { i18n } from "../translation"
import { parseQueryString } from "../urlUtil"
import { MoreLikeThisTab } from "./moreLikeThisTab"
import { TheatermodeSatisfactionScore } from "./satisfactionScore"
import { ShareTab } from "./shareTab"
import { openPhotoVideoTabRequest } from "./userActionEvents"
import type { IFollowing } from "../follow"
import type { IRoomDossier } from "../roomDossier"

enum RoomTab {
    BIO = "Bio",
    PICS_VIDEOS = "Pics & Videos",
    SHARE = "Share",
    MORE_LIKE_THIS = "More Rooms Like This",
}


export class TheaterModeRoomTabs extends RoomTabs {
    private satisfactionScore: TheatermodeSatisfactionScore
    private followUnfollowSection: FollowUnfollowSection
    private fanClubButton: FanclubButton
    private roomName: string
    // Tab page action disabled on page load
    private allowTabPageActions = false
    protected defaultTab = RoomTab.BIO
    protected activeTabName: RoomTab

    constructor() {
        super()
        this.element.style.minHeight = "200px"

        const feedbackSection = document.createElement("div")
        feedbackSection.dataset["paction"] = "BroadcasterFeedback"
        this.tabBar.element.insertBefore(feedbackSection, this.tabsWrapper.element)

        this.satisfactionScore = new TheatermodeSatisfactionScore(this.tabBar.element)
        this.fanClubButton = new FanclubButton()
        this.followUnfollowSection = new FollowUnfollowSection(this.tabBar.element)
        feedbackSection.appendChild(this.followUnfollowSection.element)
        feedbackSection.appendChild(this.fanClubButton.element)
        feedbackSection.appendChild(this.satisfactionScore.element)

        const photoVideos = new PhotoVideos(true)

        this.addTab(RoomTab.BIO, i18n.bioText, new BioContentTab())
        this.addTab(RoomTab.PICS_VIDEOS, i18n.picsAndVideosText, photoVideos)
        this.addTab(RoomTab.MORE_LIKE_THIS, i18n.moreRoomsLikeThisText, new MoreLikeThisTab())
        this.addTab(RoomTab.SHARE, i18n.shareText, new ShareTab())

        this.makeTabActive(this.defaultTab)
        const expandableDropDownMenu = this.constructExpandableDropDownMenu()

        this.tabsWrapper.addChild(expandableDropDownMenu)
        this.tabsWrapper.element.dataset["paction"] = "RoomTabs"

        // storedTab overrules defaultTab
        this.loadDefaultOrSelectedTab()

        openPhotoVideoTabRequest.listen((id) => {
            this.makeTabActive(RoomTab.PICS_VIDEOS, id)
        })

        roomLoaded.listen((context) => {
            this.roomName = context.chatConnection.room()
            this.initRoom(this.roomName === context.chatConnection.username(), context.dossier)
            photoVideos.initRoom(this.roomName)
        })

        followingEvent.listen((followingEvent: IFollowing) => {
            if (followingEvent.roomName === this.roomName) {
                if (followingEvent.following) {
                    this.followUnfollowSection.showUnfollow(followingEvent.followNotificationFrequency)
                } else {
                    this.followUnfollowSection.showFollow()
                }
            }
        })

        this.allowTabPageActions = true
    }

    private loadDefaultOrSelectedTab(): void {
        const storedTab = this.checkTabLocalStorage() as RoomTab
        const tabQuery = parseQueryString(window.location.search)["tab"]
        const queryMappings: Record<string, RoomTab> = {
            "bio": RoomTab.BIO,
            "photos": RoomTab.PICS_VIDEOS,
            "gallery": RoomTab.PICS_VIDEOS,
        }
        const queriedTab = tabQuery === undefined ? undefined : queryMappings[tabQuery]
        // More rooms and share tab are conditional, so omitted from this.
        if (queriedTab !== undefined) {
            this.makeTabActive(queriedTab)
        } else if (storedTab !== undefined) {
            this.makeTabActive(storedTab)
        }
    }

    private initRoom(isOwnRoom: boolean, dossier: IRoomDossier): void {
        if (isAnonymous() || currentSiteSettings.isWhiteLabel) {
            this.hideTabAnchor(RoomTab.SHARE)
        } else {
            this.showTabAnchor(RoomTab.SHARE)
        }

        if (isOwnRoom) {
            this.hideTabAnchor(RoomTab.MORE_LIKE_THIS)
            if (this.activeTabName === RoomTab.MORE_LIKE_THIS) {
                this.makeTabActive(RoomTab.BIO)
            }
        } else {
            this.showTabAnchor(RoomTab.MORE_LIKE_THIS)
        }

        if (dossier.roomStatus === RoomStatus.Offline) {
            const utm_content = parseQueryString(window.location.search)["utm_content"]
            if (utm_content !== undefined && NOTIFY_UTM_CONTENT.includes(utm_content)) {
                this.makeTabActive(RoomTab.MORE_LIKE_THIS)
            }
        }

        if (dossier.following) {
            this.followUnfollowSection.showUnfollow(dossier.followNotificationFrequency)
        } else {
            this.followUnfollowSection.showFollow()
        }
    }

    repositionChildren(): void {
        this.satisfactionScore.repositionChildren()
    }

    protected makeTabActive(text: RoomTab, showArgument?: number, callback?: () => void): void {
        const activeTabName = this.activeTabName
        super.makeTabActive(text, showArgument, callback)
        if (this.allowTabPageActions && activeTabName !== text) {
            addPageAction("RoomTabClicked", { "tabName": text })
        }
    }

    protected checkTabLocalStorage(): string | undefined {
        if (isLocalStorageSupported()) {
            const storedTabText = super.checkTabLocalStorage()
            if (storedTabText !== undefined) {
                return storedTabText
            }
        }
        return undefined
    }

    protected setTabLocalStorage(text: string): void {
        if (isLocalStorageSupported()) {
            super.setTabLocalStorage(text)
        }
    }
}
