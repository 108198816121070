import { isRoomRoomlistSpaActive, PageType, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { RoomStatus } from "@multimediallc/web-utils"
import {
    getScrollingDocumentElement,
    isiPad,
    isMobileDevice,
    isSamsungTablet,
    isSupportedBrowser,
} from "@multimediallc/web-utils/modernizr"
import { tokenBalanceUpdate } from "../../cb/api/tipping"
import { MobileSiteNotice } from "../../cb/components/MobileSiteNotice"
import { isRoomRoomlistSpaEligiblePage } from "../../cb/components/roomlist/spaHelpers"
import { smcOnRoomPlaybackStart } from "../../cb/components/showMyCam/smcBroadcaster"
import { updateHeaderJoinLinkRoom, updateLoginOverlayNextElement } from "../../cb/djangoTemplateUtil"
import { pageContext, PageLocation, roomDossierContext, spaPageContext } from "../../cb/interfaces/context"
import { currentSiteSettings } from "../../cb/siteSettings"
import { resizeDebounceEvent } from "../../cb/ui/responsiveUtil"
import { showRoomSignupPopup } from "../../cb/ui/roomSignupPopup"
import { updateTokencountElements } from "../../cb/ui/tipping"
import { SubSystemType } from "../../common/debug"
import { addEventListenerPoly, onceEventListenerPoly } from "../addEventListenerPolyfill"
import { modalConfirm } from "../alerts"
import { normalizeResource } from "../api"
import { ChatRoot } from "../chatRoot"
import { getChatMode } from "../chatSettings"
import { roomLoaded } from "../context"
import { isElementInViewport } from "../DOMutils"
import { DraggableCanvas } from "../fullvideolib/draggableCanvas"
import { openTipCalloutRequest, reportAbuseRequest } from "../fullvideolib/userActionEvents"
import { cancelLogPresence } from "../logPresence"
import { addPageAction } from "../newrelic"
import { HlsPlayer } from "../player/hlsPlayer"
import { JpegPushPlayer } from "../player/jpegPlayer"
import { DeniedContents } from "../profilelib/deniedContents"
import { getRoomDossier, parseFollowNotificationFrequency, parseRoomDossier } from "../roomDossier"
import { i18n } from "../translation"
import { buildQueryString, parseQueryString } from "../urlUtil"
import { UserContextMenu, UserMenuStates } from "../userContextMenu"
import { VideoMode, videoModeHandler } from "../videoModeHandler"
import { isDocumentVisible, reloadPage } from "../windowUtils"
import { ChatPureModeHandler } from "./chatPureModeHandler"
import { bindEventListeners } from "./eventListeners"
import { GenderTabs } from "./genderTabs"
import { PasswordPrompt } from "./passwordPrompt"
import { isPrivateShowRequestFeatureActive } from "./privateShowRequestModal"
import { RoomContentsPlaceholder } from "./roomContentsPlaceholder"
import { ScanNextCam } from "./scanNextCam"
import { SessionMetrics } from "./sessionMetrics"
import { SiteNotice } from "./siteNotice"
import { TheaterModePlayer } from "./theaterModePlayer"
import { TheaterModeRoomContents } from "./theaterModeRoomContents"
import type { IPageContext } from "../../cb/interfaces/context"
import type { XhrError } from "../api"
import type { AudioHolderSound } from "../audioHolder"
import type { IRoomHistory } from "../chatRoot"
import type { IChatConnection, IRoomContext } from "../context"
import type { BoundListener } from "../events"
import type { IRoomStatusChangeNotification, ITokenBalanceUpdateNotification } from "../messageInterfaces"
import type { IRoomDossier } from "../roomDossier"
import type { IVideoModeChangeNotification } from "../videoModeHandler"
import type { ArgJSONMap } from "@multimediallc/web-utils"

let hasFirstRoomLoaded = false

export class TheaterModeRoot extends ChatRoot {
    protected player: TheaterModePlayer
    private draggableCanvas: DraggableCanvas
    private siteNotices: SiteNotice[] = []
    private passwordPrompt: PasswordPrompt
    private sessionMetrics
    private recentTouch: boolean
    private deniedContents: DeniedContents | undefined

    genderTabs?: GenderTabs
    mobileSiteNotice: MobileSiteNotice
    roomContents: TheaterModeRoomContents
    private loading: number
    private roomContentsPlaceholder: RoomContentsPlaceholder
    private playbackListener?: BoundListener<undefined>
    private scanNextCam?: ScanNextCam

    constructor(roomName: string, inlineAutoplaySupported: boolean, private context: IPageContext, sessionMetricsEnable = true) {
        super(roomName, inlineAutoplaySupported)

        if (sessionMetricsEnable) {
            this.sessionMetrics = new SessionMetrics()
        }
        this.loading = Date.now()

        if (!isRoomRoomlistSpaActive()) {
            const main = document.getElementById("main") as HTMLDivElement
            main.style.font = "1em 'UbuntuRegular',Arial,Helvetica,sans-serif"
            main.appendChild(this.element)
        } else if (spaPageContext.getState().pageLocation === PageLocation.RoomlistPage) {
            //  If the page location is of type Roomlist, we want to push room pages instead of replacing the value in history.
            hasFirstRoomLoaded = true
        }

        this.element.dataset.testid = "theatermode-root"
        this.element.style.position = "static"
        this.element.style.marginRight = "15px"
        this.element.style.boxSizing = "border-box"
        this.element.focus()
        this.setupAudio()
        this.setupLoginOverlay()
        if (!isRoomRoomlistSpaActive()) {
            this.createSiteNotices()
        }
        this.createRoomContents(roomName, inlineAutoplaySupported)
        this.showLoadingPlaceholder()
        this.setupTipCallout("theater", this.roomContents.topSectionWrapper, this.roomContents.videoPanel.sendTipButton)
        this.setupResizeHandlers()
        if (isPrivateShowRequestFeatureActive()) {
            this.setupPrivateShowRequestOverlay()
        }
        this.preventMouseMoveOnRecentTouch()
        this.setupLoadRoom(roomName)

        // Neither popstate nor pageshow reliably fire for history navigation when the history comes from a js
        // pushState or replaceState, but the popstate/pageshow listeners here each cover what the other misses
        addEventListenerPoly("popstate", window, (event: PopStateEvent) => {
            if (!isRoomRoomlistSpaActive()) {
                if (event.state !== undefined && event.state !== null) {
                    if (event.state["type"] === "room") {
                        this.loadRoom(event.state["room"], false)
                    } else {
                        this.navigatePath(event.state["path"])
                    }
                }
            } else {
                const room = UrlState.current.state.room
                if (room !== undefined) {
                    this.loadRoom(room, false)
                }
            }
        })

        addEventListenerPoly("pageshow", window, (event: PageTransitionEvent) => {
            if (!isRoomRoomlistSpaActive()) {
                if (event.persisted && this.chatConnection.room() !== this.loadedRoom.room) {
                    this.loadRoom(this.loadedRoom.room, false)
                }
            } else if (UrlState.current.state.room !== undefined) {
                if (event.persisted && (
                    this.chatConnection === undefined || this.chatConnection.room() !== this.loadedRoom.room
                )) {
                    // chatConnection and loadedRoom.room can be undefined for RoomRoomlistSPA, so use URL to load the
                    // room, also RoomRoomlistSPA doesn't use setHistory, so we don't need to set it here
                    this.loadRoom(UrlState.current.state.room, false)
                }
            }
        })

        tokenBalanceUpdate.listen((tokenBalanceUpdateNotification) => {
            this.handleTokenBalanceUpdate(tokenBalanceUpdateNotification)
        })

        if (isRoomRoomlistSpaActive()) {
            UrlState.current.listen(["pageType"], (state, prevState) => {
                if (state.pageType === PageType.ROOM || prevState.pageType === PageType.ROOM) {
                    this.updateState()
                }
            }, this.element)
            this.updateState()
        }

        this.repositionChildrenRecursive()
        this.afterDOMConstructedIncludingChildren()
    }

    updateState(): void {
        if (UrlState.current.state.pageType === PageType.ROOM) {
            this.spaShowRoom()
        } else {
            this.spaHideRoom()
        }
        this.scanNextCam?.showOrHideElement(UrlState.current.state.pageType === PageType.ROOM)
        if (UrlState.current.state.pageType !== PageType.ROOM) {
            this.spaNavigateCleanupRoom()
        }
    }

    protected handleRoomStatusChangeNotification(roomStatusChangeNotification: IRoomStatusChangeNotification): void {
        const roomLockedStates = [
            RoomStatus.Away, RoomStatus.PrivateNotWatching,
            RoomStatus.Hidden, RoomStatus.Offline, RoomStatus.Unknown, RoomStatus.NotConnected,
            RoomStatus.PasswordProtected,
        ]
        this.player.videoDisabled = roomLockedStates.indexOf(roomStatusChangeNotification.currentStatus) !== -1
    }

    protected getRoomDossierPromise(username: string): Promise<IRoomDossier> {
        if (!hasFirstRoomLoaded && pageContext.current.isLoadedFromCache !== true && window["initialRoomDossier"] !== undefined) {
            return parseRoomDossier(username, window["initialRoomDossier"])
        }
        return getRoomDossier(username)
    }

    protected onRoomDossierLoad(roomDossier: IRoomDossier, setHistory: boolean): void {
        // if user navigates to different page before room dossier is loaded, don't load the room
        if (isRoomRoomlistSpaActive() && spaPageContext.getState().pageLocation !== PageLocation.RoomPage) {
            return
        }
        super.onRoomDossierLoad(roomDossier, setHistory)

        addPageAction("LoadRoom")
        this.sessionMetrics?.bindChatConnection(this.chatConnection)

        // remove old listener before adding new one if exists
        this.playbackListener?.removeListener()
        this.playbackListener = this.player.playerComponent.playbackStart.listen(() => {
            smcOnRoomPlaybackStart(roomDossier, this.player.playerComponent)
        })
    }

    protected onRoomDossierError(e: XhrError | Error, username: string): void {
        super.onRoomDossierError(e, username)
        if (isRoomRoomlistSpaActive()) {
            this.resetLoadedRoom()
        }
    }

    protected handlePlaySound(sound: AudioHolderSound): void {
        if (!isDocumentVisible() && (isMobileDevice() || isSamsungTablet() || isiPad())) {
            return
        }
        this.audioHolder.playSound(sound, this.tipVolume * this.player.playerComponent.getVolume() / 100)
    }

    protected handleSetWidescreen(isWidescreen: boolean): void {
        super.handleSetWidescreen(isWidescreen)
        this.player.setIsWidescreen(isWidescreen)
    }

    protected handleHistory(roomHistory: IRoomHistory, queryString = true): void {
        if (isRoomRoomlistSpaActive()) {
            return this.handleHistorySPA(roomHistory.room)
        }
        let fullQueryString = ""
        if (queryString) {
            const parsedQueryString = parseQueryString(window.location.search)
            if (hasFirstRoomLoaded) {
                delete parsedQueryString.join_overlay
            }

            const queryString = buildQueryString(parsedQueryString)
            if (queryString !== "") {
                fullQueryString = `?${queryString}`
            }
        }

        if (!hasFirstRoomLoaded) {
            window.history.replaceState({
                "type": "room",
                "room": roomHistory.room,
            }, roomHistory.roomTitle, normalizeResource(`/${roomHistory.room}/${fullQueryString}`))
        } else {
            window.history.pushState({
                "type": "room",
                "room": roomHistory.room,
            }, roomHistory.roomTitle, normalizeResource(`/${roomHistory.room}/${fullQueryString}`))
        }
    }

    protected handleHistorySPA(roomName: string): void {
        // todo: rename method to handleHistory in cleanup
        if (!isRoomRoomlistSpaActive()) {
            return
        }
        UrlState.current.setPartialState({ pageType: PageType.ROOM, room: roomName }, !hasFirstRoomLoaded)
    }

    protected handleRoomInitiallyOffline(): void {
        super.handleRoomInitiallyOffline()
        this.roomContents.showOfflineContent()
        this.tipCallout.setTipButtonSpan(this.roomContents.sendTipButton)
        this.player.playerComponent.disable(true)
    }

    protected handleRoomInitiallyOnline(): void {
        super.handleRoomInitiallyOnline()
        this.roomContents.hideOfflineContent()
        this.tipCallout.setTipButtonSpan(this.roomContents.videoPanel.sendTipButton)
        this.player.playerComponent.disable(false)
    }

    protected isRoomInitiallyOffline(dossier: IRoomDossier): boolean {
        // Theatermode displays a custom offline message if it is not on the testbed site
        return super.isRoomInitiallyOffline(dossier) && !pageContext.current.isTestbed
    }

    protected handleRoomStatusPasswordProtected(chatConnection: IChatConnection): void {
        super.handleRoomStatusPasswordProtected(chatConnection)
        this.passwordPrompt.show(chatConnection.room())  // eslint-disable-line @typescript-eslint/no-floating-promises
        this.hideLoadingPlaceholder()
        this.roomContents.element.style.display = "none"
        if (!isRoomRoomlistSpaActive() && this.genderTabs instanceof GenderTabs) {
            this.genderTabs.setActiveRoomTab(chatConnection.room())
        }

        // Prevent reload by logPresence. Will be restarted by roomLoaded if the user enters the password correctly
        cancelLogPresence()
    }

    protected handleRoomAccessDenied(username: string, p: ArgJSONMap): void {
        if (!isRoomRoomlistSpaActive()) {
            return super.handleRoomAccessDenied(username, p)
        }
        this.hideLoadingPlaceholder()
        this.roomContents.hideElement()
        const context = p.getParsedSubMap("ts_context")
        this.deniedContents = this.addChild(new DeniedContents({
            roomName: username,
            isFollowing: context.getBoolean("isFollowing", false),
            followNotificationFrequency: parseFollowNotificationFrequency(context.getString("followNotificationFrequency")),
            deniedMessage: p.getString("detail"),
        }))
        this.element.style.overflow = ""
    }

    protected handleRoomUnauthorized(username: string): void {
        this.handleHistory({ room: username, roomTitle: `Chat with ${username}` })
        if (this.cleanupLastRoomCallback !== undefined) {
            this.cleanupLastRoomCallback()
        }
        this.resetLoadedRoom()
        this.passwordPrompt.show(username)  // eslint-disable-line @typescript-eslint/no-floating-promises
        this.roomContents.element.style.display = "none"
        if (!isRoomRoomlistSpaActive() && this.genderTabs instanceof GenderTabs) {
            this.genderTabs.setActiveRoomTab(username)
        }
        updateHeaderJoinLinkRoom(username)
        // this can be removed after TS login overlay replaces django template login overlay
        updateLoginOverlayNextElement(username)
    }

    protected handleRoomPasswordRequired(username: string): void {
        this.handleHistory({ room: username, roomTitle: `Chat with ${username}` })
        if (this.cleanupLastRoomCallback !== undefined) {
            this.cleanupLastRoomCallback()
        }
        this.resetLoadedRoom()
        this.hideLoadingPlaceholder()
        this.passwordPrompt.show(username)  // eslint-disable-line @typescript-eslint/no-floating-promises
        this.roomContents.element.style.display = "none"
        if (!isRoomRoomlistSpaActive() && this.genderTabs instanceof GenderTabs) {
            this.genderTabs.setActiveRoomTab(username)
        }
        updateHeaderJoinLinkRoom(username)
        // this can be removed after TS login overlay replaces django template login overlay
        updateLoginOverlayNextElement(username)
    }

    protected handleRoomDossierErrorDefault(errorText: string, errorExtra?: object): void {
        super.handleRoomDossierErrorDefault(errorText, errorExtra)
        if (!isRoomRoomlistSpaActive()) {
            return
        }
        const modalConfig = {
            acceptText: i18n.refresh,
            declineText: i18n.close,
        }
        modalConfirm(i18n.pageLoadError, () => reloadPage(), () => {}, modalConfig)
    }

    private createSiteNotices(): void {
        roomLoaded.listen((context: IRoomContext) => {
            if (this.siteNotices.length !== 0) {
                return
            }
            if (context.dossier.showMobileSiteBannerLink) {
                this.mobileSiteNotice.show()
            }
            if (context.dossier.tokenBalance > 0 && !context.dossier.tfaEnabled) {
                const tfaNotice = new SiteNotice(i18n.addTFANotification(), { cacheKey: `tfa-msg-${context.dossier.userName}` })
                this.addChildBeforeIndex(tfaNotice, 0)
                tfaNotice.messageRemoved.listen(() => {
                    this.repositionChildren()
                })
                this.siteNotices.splice(0, 0, tfaNotice)
            }

            if (!isSupportedBrowser()) {
                const ieNotice = new SiteNotice(i18n.ieSupportNotification(), { cacheKey: "ie-support-notice" })
                this.addChildBeforeIndex(ieNotice, 0)
                ieNotice.messageRemoved.listen(() => {
                    this.repositionChildren()
                })
                this.siteNotices.splice(0, 0, ieNotice)
            }
        })
    }

    private createDraggableCanvas(): void {
        this.draggableCanvas = this.sessionMetrics !== undefined ?
            new DraggableCanvas("theater", this.sessionMetrics) :
            new DraggableCanvas("theater")
        this.draggableCanvas.element.style.left = "0"
        this.draggableCanvas.element.style.top = "0"
        this.draggableCanvas.chatWindow.element.style.zIndex = ""
        // start fixed due to size of videoPanel on Default startup.  This will later be set by RoomContents on videoMode change
        this.draggableCanvas.element.style.position = "fixed"
        this.draggableCanvas.element.style.width = "100%"
        new ChatPureModeHandler(this.draggableCanvas)
    }

    private createPlayer(roomName: string, inlineAutoplaySupported: boolean): void {
        this.mobileSiteNotice = this.addChild(new MobileSiteNotice())
        if (!isRoomRoomlistSpaActive()) {
            this.genderTabs = this.addChild(new GenderTabs(roomName))
        }
        this.player = new TheaterModePlayer(inlineAutoplaySupported)
        if (this.player.playerComponent instanceof HlsPlayer) {
            this.player.playerComponent.setPageLoaded(this.loading)
        }
        if (!isRoomRoomlistSpaActive()) {
            this.scanNextCam = new ScanNextCam(this.genderTabs!, this.player);
            (this.genderTabs as GenderTabs).tabsRow.prependChild(this.scanNextCam)
        }
    }

    public getPlayer(): TheaterModePlayer {
        return this.player
    }

    private createRoomContents(roomName: string, inlineAutoplaySupported: boolean): void {
        this.createDraggableCanvas()
        this.createPlayer(roomName, inlineAutoplaySupported)
        addEventListenerPoly("click", this.element, (event: MouseEvent) => {
            if (
                videoModeHandler.getVideoMode() !== VideoMode.Split &&
                !this.player.videoControls.element.contains(event.target as HTMLElement)
            ) {
                this.player.videoControls.toggle()
            }
        })
        addEventListenerPoly("pointerdown", this.element, () => {
            if (videoModeHandler.getVideoMode() !== VideoMode.Split) {
                if (this.draggableCanvas.focusedWindow === this.draggableCanvas.chatWindow &&
                    this.draggableCanvas.hoveredWindow !== this.draggableCanvas.chatWindow &&
                    UserContextMenu.state === UserMenuStates.Down) {
                    this.draggableCanvas.setFocusedWindow(undefined)
                }
            }
        })

        this.roomContents = this.addChild(new TheaterModeRoomContents(this.player, this.draggableCanvas))
        this.player.videoControls.fullscreenDiv = this.roomContents.videoPanel.element
        this.player.element.appendChild(this.player.videoQualityModal.overlay)
        this.player.element.appendChild(this.player.videoQualityModal.element)

        this.draggableCanvas.element.style.visibility = "hidden"
        this.roomContents.topSectionWrapper.style.position = "fixed"
        this.roomContents.topSectionWrapper.style.top = "999999px"

        this.passwordPrompt = this.addChild(new PasswordPrompt(true))

        const shouldExecuteKeyHandlers = isRoomRoomlistSpaEligiblePage() ? () => {
            return UrlState.current.state.pageType === PageType.ROOM && this.roomContents.isShown()
        } : undefined
        bindEventListeners(this.draggableCanvas, this.roomContents.chatTabContainer, shouldExecuteKeyHandlers)

        videoModeHandler.changeVideoMode.listen((videoModeChangeNotification: IVideoModeChangeNotification) => {
            this.handleChangeVideoMode(videoModeChangeNotification)
        })

        getChatMode.fire("theater")

        // Video mode init requires videoModeHandler to fire changeVideoMode, but isn't ready for the event yet on
        // videoModeHandler init, so we fire it here. Ideally we should refactor to not need this unclear event timing.
        videoModeHandler.setFireVideoMode(videoModeHandler.getVideoMode(), { fire: true })
    }

    private handleChangeVideoMode(videoModeChangeNotification: IVideoModeChangeNotification): void {
        debug(`Video mode changed from ${videoModeChangeNotification.previousMode} to ${videoModeChangeNotification.currentMode}`)
        videoModeHandler.setVideoMode(videoModeChangeNotification.currentMode)

        this.autoScrollPage()
        switch (videoModeHandler.getVideoMode()) {
            case VideoMode.Split:
                if (this.draggableCanvas.tipWindow !== undefined) {
                    openTipCalloutRequest.fire({})
                }
                if (this.draggableCanvas.abuseReportWindow !== undefined) {
                    reportAbuseRequest.fire(undefined)
                }
                if (this.sessionMetrics !== undefined) {
                    this.sessionMetrics.bindTipCallout(this.tipCallout)
                }
                this.player.playerComponent.showControls()
                break
            case VideoMode.Theater:
                if (this.draggableCanvas.abuseReportWindow !== undefined) {
                    reportAbuseRequest.fire(undefined)
                }
                this.player.playerComponent.showControls()
                break
            case VideoMode.IFS:
                this.player.playerComponent.showControls()
                this.tipCallout.hide()
                break
            case VideoMode.Fullscreen:
                break
            default:
                error(`Unexpected VideoMode: ${videoModeHandler.getVideoMode()}`, {}, SubSystemType.Video)
        }
        this.repositionChildrenRecursive()
        // fix the padding/margin
        resizeDebounceEvent.fire(false)
    }

    repositionChildrenRecursive(): void {
        super.repositionChildrenRecursive()
    }

    private autoScrollPage(): void {
        if (!hasFirstRoomLoaded || roomDossierContext.getState().roomStatus === RoomStatus.Offline) {
            return
        }
        // Sometimes when loading a new roomlist page, scrollToIdealPosition will fail and prevent roomRoot from being created
        if (isRoomRoomlistSpaActive() && spaPageContext.getState().pageLocation !== PageLocation.RoomPage) {
            return
        }

        if (document.readyState === "complete") {
            this.scrollToIdealPosition()
        } else {
            // Bypass browser sometimes automatically restoring previous scroll for the page after load, esp if reloading
            onceEventListenerPoly("load", window, () => {
                // In most cases a timeout of 0 works, but some browsers, ipad in particular, need to wait a moment
                window.setTimeout(() => { this.scrollToIdealPosition() }, 100)
            })
        }
    }

    private scrollToIdealPosition(): void {
        // Priority 1: show the gender tabs.
        // Priority 2: show the entire send message button.
        // Priority 3: show the site nav bar.

        const siteNavBar = document.querySelector("#header > .nav-bar")
        const alreadyShowingPriorityElems = siteNavBar !== null && isElementInViewport(siteNavBar as HTMLElement)
            && isElementInViewport(this.roomContents.topSectionWrapper)

        if (siteNavBar === null || alreadyShowingPriorityElems) {
            return
        }

        // Distance from top of page to *top* of site nav bar
        const distToTopSiteNavBar = document.documentElement.scrollTop
            + siteNavBar.getBoundingClientRect().top

        // Distance from top of page to *bottom* of top section wrapper
        const distToBottomTopSectionWrapper = document.documentElement.scrollTop
            + this.roomContents.topSectionWrapper.getBoundingClientRect().bottom

        const idealViewportMinHeight = distToBottomTopSectionWrapper - distToTopSiteNavBar
        const scrollPadding = 5

        // Try to accomodate the scroll position that can display *both*
        // the site nav bar and the top section wrapper within the viewport.
        // Otherwise, prioritize showing the gender tabs (which in turn will try to show as much
        // of the top section wrapper as possible including the send button).
        if (idealViewportMinHeight <= window.innerHeight) {
            getScrollingDocumentElement().scrollTop = (siteNavBar as HTMLElement).offsetTop - scrollPadding
        } else {
            const genderTabs = document.querySelector(".genderTabs") as HTMLElement
            getScrollingDocumentElement().scrollTop = (genderTabs?.offsetTop ?? (siteNavBar as HTMLElement).offsetTop) - scrollPadding
        }
    }

    private scrollToTop(): void {
        getScrollingDocumentElement().scrollTop = 0
    }

    protected loadRoom(username: string, setHistory: boolean): void {
        if (this.isRoomLoaded(username)) {
            this.setRoomHistoryIfNeeded()
            this.autoScrollPage()
            this.repositionChildrenRecursive()
            return
        }
        if (!isRoomRoomlistSpaActive() && this.passwordPrompt.isShown()) {
            this.passwordPrompt.hideElement()
        }
        this.showLoadingPlaceholder()

        this.handleHistorySPA(username)

        if (this.context.loggedInUser === undefined && !currentSiteSettings.isWhiteLabel) {
            showRoomSignupPopup()
        }
        super.loadRoom(username, setHistory)
    }

    protected handleRoomLoaded(context: IRoomContext): void {
        super.handleRoomLoaded(context)
        // ideally use URLState "room" listener in modal component bases to handle hiding all modals once it's ready,
        // but for now hide modals here since popstate doesn't handle SPA navigations well
        // (https://multimediallc.leankit.com/card/30502080746332 and https://multimediallc.leankit.com/card/30502080976671)
        this.tipCallout.hide()

        this.hideLoadingPlaceholder()
        this.roomContents.element.style.display = "block"

        this.tipVolume = context.dossier.userChatSettings.tipVolume
        updateTokencountElements(context.dossier.tokenBalance)
        updateHeaderJoinLinkRoom(context.dossier.room)
        // this can be removed after TS login overlay replaces django template login overlay
        updateLoginOverlayNextElement(context.dossier.room)

        this.repositionChildrenRecursive()
        if (!hasFirstRoomLoaded) {
            hasFirstRoomLoaded = true
        }
        this.autoScrollPage()
    }

    private handleTokenBalanceUpdate(tokenBalanceUpdateNotification: ITokenBalanceUpdateNotification): void {
        this.roomContents.videoPanel.tokenBalance.innerText = `${tokenBalanceUpdateNotification.tokens}`
        this.roomContents.videoPanel.tokenBalanceSuffix.innerText = ` ${i18n.tokenOrTokensText(
            tokenBalanceUpdateNotification.tokens, false)}`
        updateTokencountElements(tokenBalanceUpdateNotification.tokens)
    }

    private preventMouseMoveOnRecentTouch(): void {
        // There doesn't seem to be any way to detect when a touch was occurred during onmousemove.
        // We are using this to prevent an unwanted behavior on when touch occurs
        this.draggableCanvas.element.ontouchstart = () => {
            this.recentTouch = true
            window.setTimeout(() => {
                this.recentTouch = false
            }, 500)
        }
        this.draggableCanvas.element.onmousemove = () => {
            if (!this.recentTouch) {
                this.player.playerComponent.showControls()
            }
        }
        if (this.player.playerComponent instanceof JpegPushPlayer) {
            this.player.playerComponent.element.ontouchstart = () => {
                if (videoModeHandler.getVideoMode() === VideoMode.Split) {
                    this.recentTouch = true
                    window.setTimeout(() => {
                        this.recentTouch = false
                    }, 500)
                }
            }
            this.player.playerComponent.element.onmousemove = () => {
                if (videoModeHandler.getVideoMode() === VideoMode.Split) {
                    if (!this.recentTouch) {
                        this.player.playerComponent.showControls()
                    }
                }
            }
        }
    }

    private navigatePath(path: string): void {
        window.location.href = normalizeResource(path)
    }

    private setRoomHistoryIfNeeded(): void {
        if (window.location.pathname !== `/${this.loadedRoom.room}/` && this.loadedRoom.room !== "") {
            this.handleHistory(this.loadedRoom, false)
        }
    }

    private showLoadingPlaceholder(): void {
        if (!isRoomRoomlistSpaActive() || this.roomContents === undefined) {
            return
        }
        if (this.passwordPrompt.isShown()) {
            this.passwordPrompt.hideElement()
        }
        this.roomContents.hideElement()
        if (this.roomContentsPlaceholder === undefined) {
            this.roomContentsPlaceholder = this.addChild(new RoomContentsPlaceholder({ roomContents: this.roomContents }))
        } else {
            this.roomContentsPlaceholder.show()
        }
    }

    private hideLoadingPlaceholder(): void {
        if (!isRoomRoomlistSpaActive() || this.roomContents === undefined) {
            return
        }

        this.roomContentsPlaceholder.hideElement()
    }

    public spaShowRoom(defaultDisplay = "block"): void  {
        if (!isRoomRoomlistSpaActive()) {
            return
        }
        super.showElement(defaultDisplay)
        this.scrollToTop()
    }

    public spaHideRoom(): void {
        if (!isRoomRoomlistSpaActive()) {
            return
        }
        super.hideElement()
        if (this.deniedContents !== undefined) {
            this.removeChild(this.deniedContents)
            this.deniedContents = undefined
            this.element.style.overflow = "hidden"
        }
    }

    public spaNavigateCleanupRoom(): void {
        // this.cleanupLastRoomCallback is only set when a room has been loaded
        if (!isRoomRoomlistSpaActive() || this.cleanupLastRoomCallback === undefined) {
            return
        }
        this.cleanupLastRoomCallback()
        // chatConnection is disconnected so loadedRoom must be reset
        this.resetLoadedRoom()
        this.player.playerComponent.stopVideoAndMetrics()
    }
}
