import { usernameTitleCase } from "@multimediallc/web-utils"
import { addColorClass, colorClass } from "../../cb/colorClasses"
import { currentSiteSettings } from "../../cb/siteSettings"
import { DjangoForm, DjangoFormParser } from "../../cb/ui/djangoForm"
import { getCb, normalizeResource } from "../api"
import { isNotLoggedIn } from "../auth"
import { Component } from "../defui/component"
import { exitFullscreen, FULLSCREEN_CLOSING_TIME_TO_WAIT, isFullscreen } from "../fullscreen"
import { loadRoomRequest } from "../fullvideolib/userActionEvents"
import { i18n } from "../translation"
import { goToHomepage } from "../windowUtils"
import type { IDjangoFormData } from "../../cb/ui/djangoForm"
import type { XhrError } from "../api"

export class PasswordPrompt extends Component {
    private room: string
    private form: PasswordPromptForm
    private formEndpoint: string

    constructor(private asSPA: boolean) {
        super()

        this.element.style.display = "none"
        this.element.style.position = "static"
        this.element.style.margin = "21px 19px"
        this.element.style.width = ""
        addColorClass(this.element, colorClass.defaultColor)

        const text = document.createElement("h1")
        text.innerText = i18n.roomRequiresPasswordText
        text.style.fontFamily = "'UbuntuRegular', Arial, Helvetica, sans-serif"
        text.style.fontWeight = "700"
        this.element.appendChild(text)
    }

    public async show(room: string, rawFormData?: string): Promise<void> {
        this.room = room
        this.formEndpoint = `api/ts/chat/roomloginform/${this.room}/`

        if (isFullscreen()) {
            exitFullscreen()
            await new Promise(resolve => window.setTimeout(resolve, FULLSCREEN_CLOSING_TIME_TO_WAIT))
        }

        if (isNotLoggedIn(i18n.loginForPasswordPrompt(usernameTitleCase(this.room)), false, goToHomepage, { requiredForPage: true })) {
            return
        }

        this.removeAllChildren()

        if (rawFormData !== undefined) {
            this.createForm(rawFormData)
        } else {
            await getCb(this.formEndpoint).then((xhr) => {
                this.createForm(xhr.responseText)
            })
        }

        // In SPA nav to a passworded room, PasswordPrompt is shown before the room loads and sets the document title for itself
        document.title = i18n.documentTitle(room, currentSiteSettings.siteName)

        this.showElement()
    }

    private createForm(rawFormData: string): void {
        try {
            const data = DjangoFormParser.parseData(rawFormData)
            const onSuccess = () => {
                if (this.asSPA) {
                    loadRoomRequest.fire(this.room)
                    this.hideElement()
                } else {
                    window.location.href = normalizeResource(`/${this.room}/`)
                }
            }
            const onError = (err: XhrError) => {
                const response = JSON.parse(err.xhr.responseText)
                let requiresCaptcha = false
                if (response["extra"] !== undefined && response["extra"]["requires_captcha"] !== undefined) {
                    requiresCaptcha = response["extra"]["requires_captcha"]
                }
                if (requiresCaptcha !== this.form.hasCaptcha()) {
                    // Reload form to get or remove the captcha
                    this.removeChild(this.form)
                    this.show(this.room)  // eslint-disable-line @typescript-eslint/no-floating-promises
                }
            }

            this.form = new PasswordPromptForm(data, this.formEndpoint, onSuccess, onError)
            this.form.setSubmitText(i18n.loginToRoom)
            this.form.getSubmitButton().dataset.testid = "room-password-submit-btn"
            this.form.getField("password")!.getWidget().dataset.testid = "room-password-input"
            this.addChild(this.form)
        } catch (err) {
            error("Error parsing form data", { "error": err, "formdata": rawFormData })
        }
    }
}

class PasswordPromptForm extends DjangoForm {
    constructor(data: IDjangoFormData, endpoint: string, onSubmitSuccess: () => void, onSubmitError: (err: XhrError) => void) {
        super(data, endpoint, { onSubmitSuccess: onSubmitSuccess, onSubmitError: onSubmitError })
    }

    public hasCaptcha(): boolean {
        return this.data.schema.fields.hasOwnProperty("prove_you_are_human")
    }
}
