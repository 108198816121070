import { UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { ArgJSONMap } from "@multimediallc/web-utils"
import { RoomListSource } from "@multimediallc/web-utils/types"
import { addColorClass, colorClass } from "../../cb/colorClasses"
import { parseIRoomInfo, RoomCard } from "../../cb/components/roomlist/roomCard"
import { RoomList } from "../../cb/components/roomlist/roomList"
import { isRoomRoomlistSpaEligiblePage } from "../../cb/components/roomlist/spaHelpers"
import { pageContext, spaPageContext } from "../../cb/interfaces/context"
import { isRoomAnimationEnabled, MILLISECOND_MULTIPLIER, ROOM_RELOAD_SECONDS, RoomReload } from "../../cb/roomList"
import { getCb } from "../api"
import { roomLoaded } from "../context"
import { Component } from "../defui/component"
import { i18n } from "../translation"
import type { IRoomInfo } from "../../cb/components/roomlist/IRoomInfo"
import type { IRoomCardProps } from "../../cb/components/roomlist/roomCard"
import type { IRoomClickDetails } from "../../cb/components/roomlist/roomList"
import type { ITabInstance } from "../roomTabs"

class MoreLikeThisRoomList extends RoomList<RoomCard> {
    private roomsJson: IRoomInfo[]

    constructor() {
        super({
            roomListSource: RoomListSource.MoreLikeThis,
            animate: isRoomAnimationEnabled(),
            // If user has disabled locations, this will be handled from the API results in resetRooms()
            showLocation: true,
            rooms: () => this.roomsJson,
            sourceInfo: (evt: IRoomClickDetails) => evt.roomInfo.sourceInfo,
        })
        this.element.style.position = "static"
        this.element.style.overflow = "visible"
    }

    setRoomListItems(responseText: string) {
        const jsonMap = new ArgJSONMap(responseText)
        this.roomsJson = jsonMap.getList("rooms")?.map((room) => parseIRoomInfo(room)) ?? []
    }

    protected createRoomCard(roomProps: IRoomCardProps): RoomCard {
        return new RoomCard(roomProps)
    }
}

export class MoreLikeThisTab extends Component implements ITabInstance {
    private loadingMessage = document.createElement("span")
    private roomList: MoreLikeThisRoomList
    private refreshInterval: number
    private noRecs = document.createElement("div")
    private noRecsTop = document.createElement("div")
    private room: string | undefined

    constructor(room?: string) {
        super()
        if (room !== undefined) {
            this.room = room
        }

        this.element.className = "MoreRooms"
        addColorClass(this.element, colorClass.defaultColor)
        this.element.style.minHeight = "395px"
        this.element.style.padding = "15px"
        this.element.style.width = "100%"
        this.element.style.boxSizing = "border-box"
        this.element.style.position = "static"
        this.element.style.margin = "10px 0"
        this.element.dataset.testid = "more-rooms-like-this-tab-contents"

        this.loadingMessage.style.display = "block"
        this.loadingMessage.style.fontSize = "12px"
        this.loadingMessage.textContent = "loading..."

        this.element.appendChild(this.loadingMessage)

        this.noRecs.style.display = "none"
        this.noRecs.style.fontSize = "14px"
        const nrBottom = document.createElement("div")
        nrBottom.textContent = i18n.trendingRoomsInstead
        nrBottom.style.marginBottom = "10px"
        this.noRecsTop.style.marginBottom = "10px"
        this.noRecs.appendChild(this.noRecsTop)
        this.noRecs.appendChild(nrBottom)

        this.element.appendChild(this.noRecs)

        this.roomList = new MoreLikeThisRoomList()
        this.addChild(this.roomList)

        roomLoaded.listen(context => {
            this.loadingMessage.style.display = "block"
            this.room = context.dossier.room

            // Refresh the pane if we're visible during a room change
            if (this.element.style.display === "block") {
                this.show()
            }
        })

        if (isRoomRoomlistSpaEligiblePage()) {
            UrlState.current.listen(["room"], (state, prevState) => {
                if (state.room === undefined && prevState.room !== undefined) {
                    // If we're here, on SPA navigation outside of a room page we prevent
                    // unintentional content or image reloads
                    this.clearRefresh()
                }
            }, this.element)
        }
    }

    private clean(): void {
        this.noRecsTop.textContent = ""
        this.noRecs.style.display = "none"
    }

    private loadContent(clean = false): void {
        if (this.room === undefined) {
            return
        }
        const thisRoom = this.room

        getCb(`api/more_like/${this.room}/`).then(response => {
            if (this.room !== thisRoom) {
                return
            }
            if (clean) {
                this.clean()
            }
            this.loadingMessage.style.display = "none"
            const parsed = new ArgJSONMap(response.responseText)
            const hasRecs = parsed.getBoolean("has_recommendations")
            if (!hasRecs) {
                this.noRecsTop.textContent = i18n.noSimilarRooms(thisRoom)
                this.noRecs.style.display = "block"
            }
            this.roomList.setRoomListItems(response.responseText)
            this.roomList.updateState()
        }).catch(err => {
            error("Failed to load more rooms like this", {
                "room": this.room,
                "xhr": err.toString(),
            })
        })
    }

    show(): void {
        this.loadingMessage.style.display = "block"
        this.element.style.display = "block"
        this.clean()
        this.loadContent()
        this.resetRefreshIntervals()
    }

    hide(): void {
        this.element.style.display = "none"
        this.loadingMessage.style.display = "none"
        this.clearRefresh()
    }

    checkBeforeSwitch(switchCallback: () => void): void {
        switchCallback()
    }

    private resetRefreshIntervals(): void {
        clearInterval(this.refreshInterval)
        const refreshFrequency = spaPageContext.getState().refreshFrequency ?? pageContext.current.refreshFrequency
        RoomReload.scheduleImageRefresh(refreshFrequency ?? 0)
        this.refreshInterval = window.setInterval(() => {
            this.loadContent()
        }, ROOM_RELOAD_SECONDS * MILLISECOND_MULTIPLIER)
    }

    private clearRefresh(): void {
        clearInterval(this.refreshInterval)
    }
}
